class CommunityBreaker {
  $communitySlider: HTMLElement;
  $communityTeaser: HTMLElement;
  $leftScrollButton: HTMLElement | null = null;
  $rightScrollButton: HTMLElement | null = null;
  enableLeftScrollButton = false;
  enableRightScrollButton = false;

  constructor($communitySlider: HTMLElement) {
    this.$communitySlider = $communitySlider;

    const $communityTeaser = this.$communitySlider.querySelector<HTMLElement>(
      '.community-breaker__teasers',
    );

    if (!$communityTeaser) {
      throw new Error('communityTeaser are missing');
    }

    this.$communityTeaser = $communityTeaser;

    // Update on scroll and initial load
    this.$communityTeaser.addEventListener('scroll', () => this.update(), {
      passive: true,
    });

    // Update on resizing of teaser-list container
    const resizeObserver = new ResizeObserver(() => this.update());
    resizeObserver.observe(this.$communityTeaser);

    // Init everything
    this.initScrollButtons();
    this.update();
  }

  initScrollButtons() {
    // Left scroll button
    this.$leftScrollButton = this.$communitySlider.querySelector(
      '.community-breaker__navigation-button--left',
    );

    this.$leftScrollButton?.addEventListener('click', (event) => {
      event.preventDefault();
      this.move('left');
    });

    // Right scroll button
    this.$rightScrollButton = this.$communitySlider.querySelector(
      '.community-breaker__navigation-button--right',
    );

    this.$rightScrollButton?.addEventListener('click', (event) => {
      event.preventDefault();
      this.move('right');
    });
  }

  move(direction: 'left' | 'right') {
    // Get first teaser
    const teaser = this.$communityTeaser.querySelector<HTMLElement>(
      '.community-breaker__teaser:nth-child(2)',
    );

    // Without a teaser, there is nothing to move
    if (!teaser) {
      return;
    }

    const { columnGap: columnGapPx } = window.getComputedStyle(
      this.$communityTeaser,
    );

    const columnGap = parseInt(columnGapPx, 10);
    const scrollWidth = teaser.clientWidth + columnGap;

    this.$communityTeaser.scrollBy({
      left: direction === 'left' ? scrollWidth * -1 : scrollWidth,
    });
  }

  update() {
    window.requestAnimationFrame(() => {
      this.$leftScrollButton?.toggleAttribute(
        'disabled',
        this.$communityTeaser.scrollLeft === 0,
      );

      this.$rightScrollButton?.toggleAttribute(
        'disabled',
        this.$communityTeaser.scrollWidth - 10 <=
          this.$communityTeaser.scrollLeft + this.$communityTeaser.offsetWidth,
      );
    });
  }
}

document
  .querySelectorAll<HTMLElement>('.community-breaker')
  .forEach(($communitySlider) => new CommunityBreaker($communitySlider));

export default CommunityBreaker;
