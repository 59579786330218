import { on } from 'delegated-events';
import { createFocusTrap, type FocusTrap } from 'focus-trap';
import abort from '../../../javascripts/utils/abort';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import moveFocus from '../../../javascripts/utils/moveFocus';

let focusTrap: FocusTrap | undefined;

on('click', 'button.extended-table__filter-button', (event) => {
  const { currentTarget: $button } = event;

  event.preventDefault();

  if (focusTrap) {
    focusTrap.deactivate();
    focusTrap = undefined;

    return;
  }

  const target = $button.getAttribute('aria-controls') ?? abort();
  const $target = document.getElementById(target) ?? abort();

  $button.setAttribute('aria-expanded', 'true');

  focusTrap = createFocusTrap([$target, $button], {
    initialFocus: false,
    clickOutsideDeactivates: true,
    returnFocusOnDeactivate: false,
    onActivate() {
      moveFocus($target);
    },
    onDeactivate() {
      $button.setAttribute('aria-expanded', 'false');
      invisibleFocus($button);
    },
    onPostDeactivate() {
      focusTrap = undefined;
    },
  });

  focusTrap.activate();
});
